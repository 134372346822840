.job-card {
    display: flex;
    border: 1px solid #ddd;
    border-radius: 3px;
    margin: 10px;
    padding: 20px;
    box-shadow: 0 1px 10px #0428f1;
  }

  .job-card:hover{
    transform: scale(1.0222);
    box-shadow: 0 1px 10px #0428f1;
    transition: transform .2s;
  }
  
  .job-info {
    flex: 1;
  }
  
  .job-title {
    font-size: 24px;
    margin: 0;
  }
  
  .company {
    font-size: 18px;
    color: #666;
  }
  
  .location {
    font-size: 16px;
    color: #132844;
    margin-top: 5px;
  }
  
  .tags {
    margin-top: 10px;
  }
  
  .tag {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    background-color: #0428f1;
    color: #ffffff;
    border-radius: 4px;
  }
  
  .description {
    font-size: 14px;
    margin-top: 10px;
  }
  
  .apply-button {
    display: flex;
    align-items: center;
  }
  
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  